var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"reserve"},[_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(" 選擇接種疫苗 "),_c('span',{staticClass:"text-small"},[_vm._v("(已選 "+_vm._s(_vm.vaccineCount)+" 種疫苗)")])])]),_c('v-card-text',[_c('v-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedVaccines),callback:function ($$v) {_vm.selectedVaccines=$$v},expression:"selectedVaccines"}},[_c('v-row',_vm._l((_vm.vaccines),function(vaccine){return _c('v-col',{key:vaccine.key,attrs:{"cols":"6","sm":"4","md":"3","lg":"2"}},[_c('v-item',{attrs:{"value":vaccine.key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [(!_vm.availableVaccines[vaccine.key])?_c('v-card',{staticClass:"d-flex align-center",attrs:{"height":"80","color":"#cacaca"}},[_c('div',{staticClass:"flex-grow-1 text-center font-weight-bold",staticStyle:{"color":"#545454"}},[_c('div',{domProps:{"innerHTML":_vm._s(vaccine.label)}})])]):_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? '#e26f31' : '#eaad8c',"height":"80"},on:{"click":function($event){return _vm.handleToggle($event, toggle, vaccine.key)}}},[_c('v-scroll-y-transition',[_c('div',{staticClass:"flex-grow-1 text-center font-weight-bold ",style:({ color: active ? '#fff' : '#2f2f2f' })},[_c('div',{domProps:{"innerHTML":_vm._s(vaccine.label)}})])])],1)]}}],null,true)})],1)}),1)],1)],1)],1),(
      _vm.selectedVaccines.includes('Flu-S') || _vm.selectedVaccines.includes('JN-S')
    )?_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(" 選擇特殊族群身份 ")])]),_c('v-card-text',[_c('v-radio-group',{staticClass:"mt-0",model:{value:(_vm.selectedSpecialGroup),callback:function ($$v) {_vm.selectedSpecialGroup=$$v},expression:"selectedSpecialGroup"}},_vm._l((_vm.specialGroups),function(specialGroup){return _c('v-radio',{key:specialGroup.label,attrs:{"value":specialGroup}},[_c('template',{slot:"label"},[_c('div',{staticClass:"my-1"},[_vm._v(" "+_vm._s(specialGroup.label)+" "),_c('br'),_vm._v(" ("+_vm._s(specialGroup.document)+") ")])])],2)}),1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v("選擇接種地點")]),_c('div',{staticClass:"reload",on:{"click":function($event){return _vm.refresh()}}},[_vm._v("[重新整理]")])]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.listRegions,"label":"請選擇行政區","hide-details":"","outlined":"","dense":""},model:{value:(_vm.selected.行政區),callback:function ($$v) {_vm.$set(_vm.selected, "行政區", $$v)},expression:"selected.行政區"}}),_c('v-select',{staticClass:"mt-4",attrs:{"placeholder":"請選擇","items":_vm.listHospitals,"no-data-text":"暫無預約地點","label":"請選擇預約地點","outlined":"","hide-details":"","dense":""},model:{value:(_vm.selected.地點),callback:function ($$v) {_vm.$set(_vm.selected, "地點", $$v)},expression:"selected.地點"}})],1)],1),(_vm.selected.地點)?_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',[_vm._v("選擇預約時間")]),_c('v-card-text',[_c('div',{staticClass:"container-section"},_vm._l((_vm.listSections),function(section){return _c('div',{staticClass:"section text-center",class:{
            active: _vm.selected.section && _vm.selected.section._id == section._id,
            disabled: section.已預約 >= section.人數上限,
          },on:{"click":function($event){return _vm.selectSection(section)}}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(section.日期))]),_vm._v(" "+_vm._s(section.開始時間)+"~"+_vm._s(section.結束時間)),_c('br'),_vm._v(" 尚可預約 "+_vm._s(section.人數上限 - section.已預約)+" 位 ")])}),0)])],1):_vm._e(),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-btn',{attrs:{"color":"","block":"","x-large":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-icon',[_vm._v("mdi-arrow-u-left-top")]),_vm._v(" 返回 ")],1)],1),_c('v-col',[_c('v-btn',{key:_vm.reserveKey,attrs:{"color":"success","block":"","x-large":"","disabled":!_vm.selectedVaccines.length ||
            !_vm.selected.section ||
            ((_vm.selectedVaccines.includes('JN-S') ||
              _vm.selectedVaccines.includes('Flu-S')) &&
              !_vm.selectedSpecialGroup)},on:{"~click":function($event){return _vm.reserve.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" 確認預約 ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }