<template>
  <v-container class="reserve">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title class="d-flex justify-space-between">
        <div>
          選擇接種疫苗
          <span class="text-small">(已選 {{ vaccineCount }} 種疫苗)</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-item-group multiple v-model="selectedVaccines">
          <v-row>
            <v-col
              v-for="vaccine in vaccines"
              :key="vaccine.key"
              cols="6"
              sm="4"
              md="3"
              lg="2"
            >
              <v-item v-slot="{ active, toggle }" :value="vaccine.key">
                <v-card
                  v-if="!availableVaccines[vaccine.key]"
                  class="d-flex align-center"
                  height="80"
                  color="#cacaca"
                >
                  <div
                    class="flex-grow-1 text-center font-weight-bold"
                    style="color: #545454"
                  >
                    <div v-html="vaccine.label"></div>
                  </div>
                </v-card>
                <v-card
                  v-else
                  :color="active ? '#e26f31' : '#eaad8c'"
                  class="d-flex align-center"
                  height="80"
                  @click="handleToggle($event, toggle, vaccine.key)"
                >
                  <v-scroll-y-transition>
                    <div
                      class="flex-grow-1 text-center font-weight-bold "
                      :style="{ color: active ? '#fff' : '#2f2f2f' }"
                    >
                      <div v-html="vaccine.label"></div>
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-card-text>
    </v-card>
    <v-card
      elevation="2"
      color="#fdfdfd"
      class="mb-4"
      v-if="
        selectedVaccines.includes('Flu-S') || selectedVaccines.includes('JN-S')
      "
    >
      <v-card-title class="d-flex justify-space-between">
        <div>
          選擇特殊族群身份
        </div>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="selectedSpecialGroup" class="mt-0">
          <v-radio
            v-for="specialGroup in specialGroups"
            :key="specialGroup.label"
            :value="specialGroup"
          >
            <template slot="label">
              <div class="my-1">
                {{ specialGroup.label }} <br />
                ({{ specialGroup.document }})
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <v-card-title class="d-flex justify-space-between">
        <div>選擇接種地點</div>
        <div class="reload" @click="refresh()">[重新整理]</div>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selected.行政區"
          :items="listRegions"
          label="請選擇行政區"
          hide-details
          outlined
          dense
        ></v-select>
        <v-select
          class="mt-4"
          placeholder="請選擇"
          v-model="selected.地點"
          :items="listHospitals"
          no-data-text="暫無預約地點"
          label="請選擇預約地點"
          outlined
          hide-details
          dense
        ></v-select>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd" class="mb-4" v-if="selected.地點">
      <v-card-title>選擇預約時間</v-card-title>
      <v-card-text>
        <div class="container-section">
          <div
            class="section text-center"
            :class="{
              active: selected.section && selected.section._id == section._id,
              disabled: section.已預約 >= section.人數上限,
            }"
            v-for="section in listSections"
            @click="selectSection(section)"
          >
            <div class="font-weight-bold">{{ section.日期 }}</div>
            {{ section.開始時間 }}~{{ section.結束時間 }}<br />
            尚可預約 {{ section.人數上限 - section.已預約 }} 位
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-row class="mt-2">
      <v-col>
        <v-btn color="" @click="$router.push('/')" block x-large>
          <v-icon>mdi-arrow-u-left-top</v-icon> 返回
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          color="success"
          @click.once="reserve"
          :key="reserveKey"
          block
          x-large
          :disabled="
            !selectedVaccines.length ||
              !selected.section ||
              ((selectedVaccines.includes('JN-S') ||
                selectedVaccines.includes('Flu-S')) &&
                !selectedSpecialGroup)
          "
        >
          <v-icon>mdi-check</v-icon> 確認預約
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import VueRadioButton from "vue-radio-button";
import { vaccines, specialGroups } from "@/libs/const.js";

export default {
  name: "Reserve",
  components: { VueRadioButton },
  data() {
    return {
      ready: false,
      form: null,
      selected: null,
      available: [],
      selectedVaccines: [],
      reserveKey: 0,
      selectedSpecialGroup: null,
    };
  },
  async created() {
    this.form = ls.get("form");
    this.resetSelected();
    this.$vloading.show();
    await this.getAvailable();
    this.$vloading.hide();
    this.ready = true;
  },
  computed: {
    _() {
      return _;
    },
    specialGroups() {
      return specialGroups;
    },
    availableVaccines() {
      return this.$store.state.person.preCheck;
    },
    vaccineCount() {
      return this.selectedVaccines.length;
    },
    vaccines() {
      return vaccines;
    },
    age() {
      let birth = parseInt(this.form.birth) + 19110000;
      return dayjs().diff(dayjs(birth.toString()), "years");
    },
    ageMonth() {
      let birth = parseInt(this.form.birth) + 19110000;
      return dayjs().diff(dayjs(birth.toString()), "months");
    },
    birthDate() {
      let birth = parseInt(this.form.birth) + 19110000;
      let month = String(birth).slice(4, 6);
      let day = String(birth).slice(6, 8);
      return dayjs().get("year") + `-${month}-${day}`;
    },

    dayjs() {
      return dayjs;
    },
    filterAvailable() {
      if (!this.selectedVaccines.length) {
        return [];
      }
      let list = this.available.filter((section) => {
        let checkVaccines = this.selectedVaccines.map((vaccine) => {
          if (vaccine == "Flu-S") {
            vaccine = "Flu";
          }
          if (vaccine == "JN-S") {
            vaccine = "JN";
          }
          return section.接種疫苗.split(",").includes(vaccine);
        });
        return _.every(checkVaccines);
      });

      list = list.filter((section) => {
        let 年齡限制 = section.年齡限制.replace("age", this.age);
        return eval(年齡限制);
      });
      return list;
    },
    listSections() {
      let list = this.filterAvailable.filter((section) => {
        return section.地點 == this.selected.地點;
      });
      list = _.sortBy(list, ["日期", "開始時間"]);
      return list;
    },
    listRegions() {
      let list = this.filterAvailable;
      let availableRegions = Object.keys(
        _.groupBy(list, (section) => section.行政區)
      );
      availableRegions = availableRegions.map((region) => ({
        text: region,
        value: region,
      }));
      availableRegions.unshift({
        text: "台南市全區",
        value: null,
      });
      return availableRegions;
    },
    listHospitals() {
      let list = this.filterAvailable.filter((hospital) =>
        this.selected.行政區 ? hospital.行政區 == this.selected.行政區 : true
      );

      let hospitals = _(list)
        .groupBy("地點")
        .map((hospital, 地點) => ({
          text: `${hospital[0].行政區} - ${
            hospital[0].地點
          } (尚可預約 ${_.sumBy(hospital, "人數上限") -
            _.sumBy(hospital, "已預約")} 位)`,
          value: 地點,
          left: _.sumBy(hospital, "人數上限") - _.sumBy(hospital, "已預約"),
        }))
        .value();
      hospitals = _.orderBy(hospitals, ["left"], ["desc"]);
      return hospitals;
    },
  },
  methods: {
    handleToggle($e, toggle, selectedVaccine) {
      switch (selectedVaccine) {
        case "JN":
        case "JNK":
        case "JNB":
        case "JN-S":
          this.selectedVaccines = _.without(
            this.selectedVaccines,
            "JN",
            "JNK",
            "JNB",
            "JN-S"
          );
          break;
        case "Flu":
        case "Flu-S":
          this.selectedVaccines = _.without(
            this.selectedVaccines,
            "Flu",
            "Flu-S"
          );
          break;
        case "13PCV":
        case "PPV":
          this.selectedVaccines = _.without(
            this.selectedVaccines,
            "13PCV",
            "PPV"
          );
          break;
      }
      this.selectedVaccines.push(selectedVaccine);
      // toggle($e);

      this.$forceUpdate();
    },
    resetSelected() {
      this.selected = {
        section: null,
        行政區: null,
        地點: null,
        場次: null,
      };
    },
    async refresh() {
      this.$gtag.event("按鈕-重新整理");
      await this.getAvailable();
    },
    async getAvailable() {
      let available = (await axios.get("/influenza/section")).data;
      this.available = available.map((section) => {
        section.已預約 =
          section.已預約 > section.人數上限 ? section.人數上限 : section.已預約;
        return section;
      });
    },
    selectSection(section) {
      if (section.已預約 >= section.人數上限) {
        return;
      }
      this.selected.section = section;
    },
    async reserve() {
      let { _id } = this.selected.section;
      if (
        (this.selectedVaccines.includes("JN-S") ||
          this.selectedVaccines.includes("Flu-S")) &&
        this.selectedSpecialGroup
      ) {
        this.form.note = `${this.selectedSpecialGroup.label}(${this.selectedSpecialGroup.document})`;
      }
      this.form.vaccines = this.selectedVaccines;
      let result = (await axios.post(`/influenza/reserve/${_id}`, this.form))
        .data;
      if (result.日期) {
        this.$toast.success("已成功預約接種！");
        setTimeout(() => {
          this.$router.push({
            name: "InfluenzaMyReservation",
          });
        }, 500);
      } else {
        this.$toast.error("預約接種失敗，請選擇其他時段！");
        this.selected.section = null;
        this.getAvailable();
        this.reserveKey++;
      }
    },
  },
  watch: {
    "selected.行政區": function() {
      this.selected.場次 = null;
    },
    "selected.地點": function() {
      this.selected.場次 = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-vaccine {
  text-align: center;
  // display: flex;
}
::v-deep .v-radio-label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 18%;
  height: 4em;
  margin: 1%;
  border-radius: 4px;
  border: 2px solid rgb(208, 208, 208);
  transition: border-color 300ms;
  &:hover {
    border-color: #c75213;
  }
}

::v-deep .v-radio-active {
  border-color: #c75213;
  background: #c75213;
  color: #fff;
  font-weight: 600;
}
.container-section {
  display: flex;
  flex-wrap: wrap;
}
.section {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 150px;
  max-width: calc(50% - 10px);
  border-radius: 4px;
  border: 2px solid rgb(208, 208, 208);
  padding: 20px 0px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border-color 300ms;
  &:hover {
    border: 2px solid #c75213;
  }
  &.active {
    border-color: #c75213;
    background: #c75213;
    color: #fff;
  }
  &.disabled {
    cursor: not-allowed;
    background: rgb(208, 208, 208);
    &:hover {
      border: 2px solid rgb(208, 208, 208);
    }
  }
}
.reload {
  cursor: pointer;
  font-size: 14px;
  color: #999;
  transition: color 300ms;
  &:hover {
    color: #c75213;
  }
}
.text-small {
  font-size: 12px;
}
</style>
