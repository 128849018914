import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    person: {},
    vaccineTypes: [
      {
        id: "CoV_AZ",
        text: "AZ",
      },
      {
        id: "CoV_Moderna",
        text: "莫德納",
      },
      {
        id: "CoV_BioNTech",
        text: "BNT",
      },
      {
        id: "CoV_BioNTech_Kid",
        text: "兒童BNT",
      },
      {
        id: "CoV_BioNTech_Baby",
        text: "幼童BNT",
      },
      {
        id: "CoV_Moderna_Kid",
        text: "幼童莫德納",
      },
      {
        id: "CoV_Medigen",
        text: "高端",
      },
      {
        id: "CoV_Novavax",
        text: "Novavax",
      },
      {
        id: "CoV_bModerna_BA1",
        text: "莫德納-雙價(BA.1)次世代疫苗",
      },
      {
        id: "CoV_bModerna_BA4/5",
        text: "莫德納-雙價(BA.4/5)次世代疫苗",
      },
      {
        id: "CoV_bModerna_BA4/5-Booster",
        text: "幼兒莫德納-雙價(BA.4/5)",
      },
      {
        id: "CoV_Moderna_XBB",
        text: "莫德納XBB",
      },
      {
        id: "CoV_Novavax_XBB",
        text: "Novavax XBB",
      },
    ],
    influenzaTypes: ["賽諾菲", "東洋", "國光", "高端", "GSK"],
    regions: [
      "中西區",
      "東區",
      "南區",
      "北區",
      "安平區",
      "安南區",
      "永康區",
      "歸仁區",
      "新化區",
      "左鎮區",
      "玉井區",
      "楠西區",
      "南化區",
      "仁德區",
      "關廟區",
      "龍崎區",
      "官田區",
      "麻豆區",
      "佳里區",
      "西港區",
      "七股區",
      "將軍區",
      "學甲區",
      "北門區",
      "新營區",
      "後壁區",
      "白河區",
      "東山區",
      "六甲區",
      "下營區",
      "柳營區",
      "鹽水區",
      "善化區",
      "大內區",
      "山上區",
      "新市區",
      "安定區",
    ],
  },
  mutations: {
    setPerson(state, person) {
      state.person = person;
    },
  },
  actions: {},
  modules: {},
});
