var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"home"},[_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","color":"#fdfdfd"}},[_c('v-card-title',[_vm._v("流感疫苗查詢")]),_c('v-card-text',[_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v("行政區")]),_c('v-row',{staticClass:"pb-6"},_vm._l((_vm.regions),function(region){return _c('v-col',{key:region,staticClass:"py-0",attrs:{"cols":"4","xs":"3","sm":"2"}},[_c('v-checkbox',{attrs:{"label":region,"value":region,"dense":"","hide-details":""},model:{value:(_vm.selectedRegions),callback:function ($$v) {_vm.selectedRegions=$$v},expression:"selectedRegions"}})],1)}),1),_c('v-divider',{staticClass:"my-2"}),_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v("疫苗種類")]),_c('v-row',{staticClass:"pb-6"},_vm._l((_vm.types),function(type){return _c('v-col',{key:type[0].key,staticClass:"py-0",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-checkbox',{attrs:{"label":type[0].type,"value":type[0].key,"dense":"","hide-details":""},model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}})],1)}),1),_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v("場次日期")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"開始日期","dense":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.selectedFromDate),callback:function ($$v) {_vm.selectedFromDate=$$v},expression:"selectedFromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{attrs:{"locale":"zh-hant"},on:{"input":function($event){_vm.dateFromMenu = false}},model:{value:(_vm.selectedFromDate),callback:function ($$v) {_vm.selectedFromDate=$$v},expression:"selectedFromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"結束日期","dense":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.selectedToDate),callback:function ($$v) {_vm.selectedToDate=$$v},expression:"selectedToDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{attrs:{"locale":"zh-hant"},on:{"input":function($event){_vm.dateToMenu = false}},model:{value:(_vm.selectedToDate),callback:function ($$v) {_vm.selectedToDate=$$v},expression:"selectedToDate"}})],1)],1)],1),_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v("預約狀況")]),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-checkbox',{attrs:{"label":"僅顯示可預約"},model:{value:(_vm.showAvailable),callback:function ($$v) {_vm.showAvailable=$$v},expression:"showAvailable"}})],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v("場次結果")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filterList,"no-data-text":"暫無場次","mobile-breakpoint":0,"footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [10, 30, -1],
        }},scopedSlots:_vm._u([{key:"item.已預約",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.已預約)+" / "+_vm._s(item.人數上限)+" ")]}},{key:"item.日期時間",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.日期)),_c('br'),_vm._v(" "+_vm._s(item.開始時間)+" ~ "+_vm._s(item.結束時間)+" ")]}},{key:"item.接種疫苗",fn:function(ref){
        var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.mapVaccines(item.接種疫苗))}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }